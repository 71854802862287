<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="resource.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('resource.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('resource.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('resource.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('resource.field.name')"
                    data-vv-name="resource.name"
                    data-vv-delay="500"
                    v-model="resource.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    trim
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'resource.name')">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="resource.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="resource.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" class="pr-0" v-if="isDescriptionVisible">
              <b-form-group :label="$t('resource.field.description')" label-for="description">
                <b-form-textarea id="description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('resource.placeholder.description')"
                  :data-vv-as="$t('resource.field.description')"
                  data-vv-name="resource.description"
                  data-vv-delay="500"
                  v-model="resource.description"
                  :max-rows="6"
                  v-validate="{ max: maxDescriptionLength }"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.description') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['description'] != null">
              <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="isPayAmountVisible">
              <b-col cols="12" md="8" class="pr-0">
                <b-form-group :label="$t('resource.field.cost')" label-for="cost">
                  <b-input-group>
                    <b-form-input id="cost" type="number" :min="0.00" :step="1" lazy-formatter
                      :formatter="floatFormatter"
                      :data-vv-as="$t('resource.field.cost')"
                      data-vv-name="resource.payAmount"
                      data-vv-delay="500"
                      v-model="resource.payAmount"
                      v-validate="{ required: true, min_value: 0.00 }"
                      :readonly="isPayAmountReadOnly"
                      trim>
                    </b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showCostError }">
                    <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.payAmount') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </template>
            
            <template v-if="customFieldMap['payAmount'] != null">
              <b-col v-for="(field, index) in customFieldMap['payAmount']" :key="'payAmount'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="resource.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" md="8" class="pr-0" v-if="isPayCurrencyVisible">
              <b-form-group :label="$t('resource.field.currency')" label-for="currency">
                <multiselect v-model="resource.payCurrency" class="custom-dropdown-options enable-option-icon"
                  :max-height="300"
                  :options="currencyOpts.filter(i => i.num >= 0).map(i => i.value)"
                  :custom-label="getCurrencyCodeOptionLabel"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false"
                  :disabled="isPayCurrencyReadOnly">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="resource.payCurrency == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ getCurrencyCodeOptionLabel(props.option) }}</span>
                  </template>
                </multiselect>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showCurrencyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.payCurrency') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['payCurrency'] != null">
              <b-col v-for="(field, index) in customFieldMap['payCurrency']" :key="'payCurrency'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="4" class="pr-0" v-if="isResourceQuotaVisible">
              <b-form-group :label="$t('resource.field.resourceQuota')" label-for="resourceQuota">
                <b-input-group>
                  <b-input-group-prepend v-if="!isResourceQuotaReadOnly">
                    <b-button id="QUOTA_SUBTRACT" @click.prevent="quotaAddMinus(-1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="QUOTA_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('resource.button.quota_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-label"
                      v-model="resource.resourceQuota" @blur="onQuotaBlur"
                      :readonly="isResourceQuotaReadOnly"/>
                  <b-input-group-append v-if="!isResourceQuotaReadOnly">
                    <b-button id="QUOTA_ADD" @click.prevent="quotaAddMinus(1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="QUOTA_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('resource.button.quota_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showResourceResourceQuotaError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.resourceQuota') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['resourceQuota'] != null">
              <b-col v-for="(field, index) in customFieldMap['resourceQuota']" :key="'resourceQuota'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" class="pr-0" v-if="isPayFrequencyVisible">
              <b-form-group :label="$t('resource.field.frequency')" label-for="frequency">
                <multiselect v-model="resource.payFrequency" class="custom-dropdown-options enable-option-icon"
                  :max-height="300"
                  :options="payFrequencyOptions.map(i => i.value)"
                  :custom-label="getFrequencyOptionLabel"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false"
                  :disabled="isPayFrequencyReadOnly">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="resource.payFrequency == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ getFrequencyOptionLabel(props.option) }}</span>
                  </template>
                </multiselect>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFrequencyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resource.payFrequency') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['payFrequency'] != null">
              <b-col v-for="(field, index) in customFieldMap['payFrequency']" :key="'payFrequency'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isTagVisible">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isStorageFileVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.files`) }}</label>
                <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="FILE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.file_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="files" :readOnly="isStorageFileReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                      :text="labelFilename(item.name, item.type)" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isStorageFileReadOnly"
                      enableClickWhenReadOnly
                    />
                  </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['files'] != null">
              <b-col v-for="(field, index) in customFieldMap['files']" :key="'files'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['notes'] != null">
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="resource[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            
          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="viewStaff" style="margin-right: auto" v-if="exists && canView('STAFF')">
          {{ $t('button.view_staff') }}
        </b-button>
        <template v-if="!isAccessDenied && ((exists && canEdit()) || (!exists && canAdd()))">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" :disabled="disableOk" v-else @click="modalOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <FileSelectorModal v-if="state.fileSelectorShow" :show.sync="state.fileSelectorShow" @ok="fileSelectorOk"/>
    
    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="RESOURCE" :customFields="customFields" links="NOTE,STORAGE_FILE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
      <!-- staff selector -->
      <StaffSelectorModalForAdmin v-if="showStaffSelector"
        :show.sync="showStaffSelector" 
        :resource="resource"
        nonAdmin
        hideOkBtn
      />
    </template>
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateFiles } from '@/helpers/file';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { cloneDeep } from 'lodash';
import { forceFileDownload, labelFilename, strRandom, loadViewProfile, updateViewProfile } from '@/helpers';
import { getPermissionDenyProperties } from '@/helpers/permission';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { resourceService, resourceLinkFileService,
         resourceLinkTagService } from '@/services';
import currencies from '@/views/management/script/currencies';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ResourceModal',
  components: {
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    CustomField: () => import('@/components/CustomField.vue'),
    Multiselect
  },
  props: {
    id:        { type: String,   default: `RESOURCE_NEW_${strRandom(5)}` },
    title:     { type: String,   default: 'New Resource' },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: "RESOURCE",
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      showStaffSelector: false,
      errorShow: false,
      settings: {},
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        fileSelectorShow:    false,
        historyShow:         false,
        noteShow:            false
      },
      resource: {
        uuId:               null,
        name:               null,
        description:        null,
        payAmount:          null,
        payCurrency:        null,
        payFrequency:       'Annually',
        identifier:         null,
        color:              null,
        resourceQuota:      1,
        readOnly:           false
      },
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      tags: [],
      files: [],
      updatedColor: null,
      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  async created() {
    this.currencyOpts = [];
    this.originResource = null;
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originNotes = [];
    this.originTags = [];
    this.originFiles = [];
    this.getModelInfo();
    this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    await this.loadSettings();
    if(this.exists) {
      this.resourceGet(this.id);
    }
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.currencyOpts = null;
    this.fieldValidateUtil = null;
    this.originResource = null;
    this.originNotes = null;
    this.originTags = null;
    this.originFiles = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `RESOURCE_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.resource.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.description');
    },
    showCostError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.payAmount');
    },
    showCurrencyError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.payCurrency');
    },
    showFrequencyError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.payFrequency');
    },
    showResourceResourceQuotaError() {
      return fieldValidateUtil.hasError(this.errors, 'resource.resourceQuota');
    },
    exists() {
      return this.id && !this.id.startsWith('RESOURCE_NEW_');
    },
    labelTitle() {
      return this.title? this.title : this.$t('resource.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxDescriptionLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "description";
      });
      return values.length !== 0 ? values[0].max : 10000;
    },
    minQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].min : 1;
    },
    maxQuotaValue() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "resourceQuota";
      });
      return values.length !== 0 ? values[0].max : 100000;
    },
    payFrequencyOptions() {
      let options = null;
      if (this.modelInfo != null) {
        const rawOptions = this.modelInfo.find(f => f.field === 'payFrequency').options;
        if (rawOptions != null) {
          options = rawOptions.map(i => {
            return { value: i, text: this.$t(`payFrequency.${i}`) }
          });
        }
      }
      if (options != null) {
        return options;
      }
      return [];
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description'])
      && ((!this.exists && this.canAdd(this.permissionName, ['description'])) || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isPayAmountVisible() {
      return this.canView(this.permissionName, ['payAmount']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payAmount'])) || this.exists)
    },
    isPayAmountReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payAmount']))
    },
    isPayCurrencyVisible() {
      return this.canView(this.permissionName, ['payCurrency']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payCurrency'])) || this.exists)
    },
    isPayCurrencyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payCurrency']))
    },
    isPayFrequencyVisible() {
      return this.canView(this.permissionName, ['payFrequency']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['payFrequency'])) || this.exists)
    },
    isPayFrequencyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['payFrequency']))
    },
    isStorageFileVisible() {
      //Link creation to check against canEdit()
      return !this.isGeneric && this.canView(this.permissionName, ['STORAGE_FILE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STORAGE_FILE'])) || this.exists)
    },
    isStorageFileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STORAGE_FILE']))
    },
    isResourceQuotaVisible() {
      return this.canView(this.permissionName, ['resourceQuota']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['resourceQuota'])) || this.exists)
    },
    isResourceQuotaReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['resourceQuota']))
    },
    disableOk() {
      return (this.original.readOnly && this.resource.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    async show(newValue) {
      if(newValue != this.state.modalShow) {
        await getCustomFieldInfo(this, 'RESOURCE');
        this.errors.clear();
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.state.noteShow = false;
        this.originNotes = [];
        this.notes = [];
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.restrictedRequiredField = null;
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        await this.loadSettings();
        if(this.exists) {
          this.resourceGet(this.id);
        } else {
          if (newValue) {
            const requiredFields = ['name', 'payAmount', 'payFrequency']
            const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (requiredCustomFields.length > 0) {
              requiredFields.push(...requiredCustomFields);
            }
            let result = this.canView2(this.permissionName, requiredFields);
            if (result.val) {
              result = this.canAdd2(this.permissionName, requiredFields)
            } 
            
            if (result.restrictedProp != null) {
              this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isAccessDenied = false;
            } else {
              this.isAccessDenied = true;
            }
          } else {
            this.isAccessDenied = false;
          }
          this.resetResourceProperties();
        }
      }
    }
  },
  methods: {
    labelFilename(name, type) {
      return labelFilename(name, type);
    },
    async loadSettings() {
      return Promise.allSettled([
        this.$store.dispatch('data/enumList'),
        loadViewProfile(this.settings, this.$store.state.authentication.user.uuId)
      ])
      .then(responses => {
        const enumResponse = responses[0]
        const loadViewProfileResponse = responses[1]

        if (enumResponse.status == 'fulfilled') {
          const response = enumResponse.value
          if (response.jobCase != null && response[response.jobCase] != null) {
            const propertyList = response[response.jobCase]
            if (propertyList != null) {
              if (propertyList.CompanyTypeEnum != null) {
                const obj = propertyList.GanttPriorityEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  list.push({ value: c, text: c, num: obj[c] })
                }
                this.priorityOptions = list
              }
              if (propertyList.CurrencyEnum != null) {
                const obj = propertyList.CurrencyEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  const found = currencies.find(i => i.code == c)
                  const text = found != null && found.name != null? `${c} (${found.name})` : c
                  list.push({ value: c, text, num: obj[c] })
                }
                this.currencyOpts = list
              }
            } 
          }
        }

        if (loadViewProfileResponse.status == 'fulfilled') {
          this.settings = loadViewProfileResponse.value
        }

        let currency = null
        if (this.settings.currency != null && this.currencyOpts.find(i => i.value == this.settings.currency && i.num > -1) != null) {
         currency = this.settings.currency;
        }
        if (currency == null && this.currencyOpts.find(i => i.num > -1) != null) {
          currency = this.currencyOpts.find(i => i.num > -1).value
        }
        this.resource.payCurrency = currency;

        this.resource.payAmount = 0;
      })
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`resource.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "RESOURCE"}).then(value => {
        self.modelInfo = value.RESOURCE.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });

      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.CompanyTypeEnum != null) {
              const obj = propertyList.GanttPriorityEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.priorityOptions = list
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.currencyOpts = list
            }
          } 
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    floatFormatter(value) {
      return value && !isNaN(value)? parseFloat(value).toFixed(2): 0.00;
    },
    resourceGet(id) {
      resourceService.query([{ uuId: id}], ['NOTE', 'TAG', 'STORAGE_FILE']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.resource;
      for (const key of Object.keys(s)) {
        s[key] = typeof data[key] !== 'undefined' ? data[key] : null;
      }

      this.original.readOnly = data.readOnly;

      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      } else {
        this.originTags.splice(0, this.originTags.length);
        this.tags.splice(0, this.tags.length);
      }
      
      //Setup Files data
      if (data.storageFileList && data.storageFileList.length > 0) {
        const list = data.storageFileList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.originFiles.splice(0, this.originFiles.length, ...list);
        this.files.splice(0, this.files.length, ...cloneDeep(list));
      }
      else {
        this.originFiles.splice(0, this.originFiles.length);
        this.files.splice(0, this.files.length);
      }
      
      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      this.originResource = cloneDeep(s);
    },
    modalOk() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.resource[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.errors.clear();

      if (this.resource.payCurrency == null) {
        this.errors.add({
          field: 'resource.payCurrency',
          msg: this.$t('error.cannot_be_blank', [this.$t('resource.field.currency')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.resourceSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    async resourceSubmit() {
      const data = cloneDeep(this.resource);
      if (this.isResourceQuotaVisible) {
        let quota = parseInt(data.resourceQuota);
        if (isNaN(quota) || quota < this.minQuotaValue) {
          data.resourceQuota = this.minQuotaValue;
        }
        if (quota > this.maxQuotaValue) {
          data.resourceQuota = this.maxQuotaValue;
        }
      }
      
      if (this.settings.currency !== this.resource.payCurrency) {
        this.settings.currency = this.resource.payCurrency;
        await updateViewProfile(this.settings, this.$store.state.authentication.user.uuId);
      }
      
      if (this.exists) {
        const denyEditProperties = getPermissionDenyProperties(this.permissionName, 'EDIT');
        for (const prop of denyEditProperties) {
          delete data[prop];
        }
      } else {
        const denyAddProperties = getPermissionDenyProperties(this.permissionName, 'ADD');
        for (const prop of denyAddProperties) {
          delete data[prop];
        }
      }
      
      if(!this.exists) {
        delete data['uuId'];
        this.resourcePost('create', data, 'Resource is added successfully');
      } else {
        this.resourcePost('update', data, 'Resource is updated successfully');
      }
    },
    async resourcePost(method, data, successMsg) {
      this.state.isSubmitting = true;
      //Skip updating resource if there is no change in resource properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedResourceProperties(data);
      }
      
      const result = {
        hasError: false,
        error: null
      }
      let resourceId = null;
      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        resourceId = await resourceService[method]([data])
        .then(response => {
          const data = response.data;
          return data[data.jobCase][0].uuId;
        })
        .catch(e => {
          result.hasError = true
          result.error = e;
        });
      } else {
        resourceId = data.uuId;
      }

      if (result.hasError) {
        this.httpAjaxError(result.error);
        this.state.isSubmitting = false;
        return;
      }

      // save the color in the profile
      this.updatedColor = data.color;
      
      let hasError = false

      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(resourceId, resourceLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`resource.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStorageFileReadOnly) {
        const fileResult = await updateFiles(resourceId, resourceLinkFileService, this.originFiles, this.files)
        if (fileResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`resource.${method}_partial`);
          if (fileResult.errorCodes.length > 0) {
            if (fileResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('file.title.list').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
          }
        }
      }
      
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }      
        
        const noteResult = await persistNotes(resourceId, this.originNotes, notes)
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`resource.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      

      this.state.isSubmitting = false;

      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg });
      } else {
        this.scrollToTop();
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `resource.${feedback.args[0]}`;
          const args = [this.$t(`resource.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$t('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        } else if ('forbidden_entity' == clue) {
          errorMsg = this.$t('error.forbidden_entity');
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetResourceProperties() {
      const keys = Object.keys(this.resource);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId' ||
           keys[i] === 'payCurrency' ||
           keys[i] === 'payAmount') {
          continue;
        }
        if (keys[i] === 'payFrequency') {
          this.resource[keys[i]] = 'Annually';
          continue;
        }
        else if (keys[i] === 'resourceQuota') {
          this.resource[keys[i]] = 1;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.resource[keys[i]] = customField.def;
            continue;
          }
        }
        this.resource[keys[i]] = null;
      }
      this.originResource = null;
      this.files = [];
      this.originFiles = [];
      if (this.show && !this.exists) {
        setTimeout(() => {
          this.resource.resourceQuota = this.minQuotaValue;
        }, 0);
      }
    },
    fileBadgeRemove: function(index) {
      this.files.splice(index,1);
    },
    fileBadgeClick(item) {
      // file download
      this.downloadProgressShow = true;
      forceFileDownload([{ uuId: item.uuId, name: labelFilename(item.name, item.type), type: item.type }], this);
    },
    fileSelectorToggle() {
      this.state.fileSelectorShow = true;
    },
    fileSelectorOk(details) {
      const currentIds = this.files.map(i => i.uuId);
      const newFiles = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newFiles) {
        this.files.push( {uuId: c.uuId, name: c.name, type: c.type} );
      }
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedResourceProperties(data) {
      //Remove those properties whose value is not changed in provided data against original resource.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalResource = this.originResource;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalResource[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    quotaAddMinus(delta) {
      if(isNaN(this.resource.resourceQuota) || (typeof this.resource.resourceQuota == 'string' && this.resource.resourceQuota.trim().length == 0)) {
        this.resource.resourceQuota = this.minQuotaValue;
        return;
      }
      const value = parseInt(this.resource.resourceQuota) + delta;
      if (value >= this.minQuotaValue && value <= this.maxQuotaValue) {
        this.resource.resourceQuota = value;
      }
    },
    onQuotaBlur() {
      if(isNaN(this.resource.resourceQuota) || (typeof this.resource.resourceQuota == 'string' && this.resource.resourceQuota.trim().length == 0)) {
        this.resource.resourceQuota = this.minQuotaValue;
      } else {
        const value = parseInt(this.resource.resourceQuota);
        if (value < this.minQuotaValue) {
          this.resource.resourceQuota = this.minQuotaValue;  
        } else if (value > this.maxQuotaValue) {
          this.resource.resourceQuota = this.maxQuotaValue;  
        }
      }
    },
    viewStaff() {
      this.showStaffSelector = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    },
    getCurrencyCodeOptionLabel(value) {
      return this.currencyOpts.find(i => i.value === value)?.text || value;
    },
    getFrequencyOptionLabel(value) {
      return this.payFrequencyOptions.find(i => i.value === value)?.text || value;
    }
  }
}
</script>
