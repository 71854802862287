var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId, "anti-shift"],
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.user.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "readOnly", $$v)
                                      },
                                      expression: "user.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  (_vm.exists ||
                    _vm.waiting ||
                    (!_vm.isUserRegistered && !_vm.canAdd())) &&
                  !_vm.user.ldapLogin
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.preSendMail },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.isUserRegistered
                                    ? "button.reset_password"
                                    : _vm.$store.state.data.status.smtpEnabled
                                    ? "button.send_activation"
                                    : "button.activate"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk,
                                },
                                on: { click: _vm.preOk },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("user.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("user.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _vm.isAvatarBannerVisible
                  ? _c("AvatarBanner", {
                      attrs: {
                        readOnly: _vm.isAvatarBannerReadOnly,
                        baseAvatarIcon: ["fad", "user-tie"],
                      },
                      on: { status: _vm.avatarBannerStatus },
                      model: {
                        value: _vm.avatarBanner,
                        callback: function ($$v) {
                          _vm.avatarBanner = $$v
                        },
                        expression: "avatarBanner",
                      },
                    })
                  : _vm._e(),
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: _vm.alertError ? "danger" : "success",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: _vm.alertError
                          ? ["fas", "triangle-exclamation"]
                          : ["far", "check"],
                      },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isEmailVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.email"),
                                      "label-for": "email",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "email",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("user.field.email"),
                                            "data-vv-name": "user.email",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isEmailReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "email", $$v)
                                            },
                                            expression: "user.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showEmailError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("user.email")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["email"] != null
                          ? _vm._l(
                              _vm.customFieldMap["email"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "email" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name": "user.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "user.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isFirstNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.firstName"),
                                      "label-for": "firstName",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "firstName",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "user.field.firstName"
                                            ),
                                            "data-vv-name": "user.firstName",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isFirstNameReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.firstName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "firstName",
                                                $$v
                                              )
                                            },
                                            expression: "user.firstName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showFistNameError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("user.firstName")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["firstName"] != null
                          ? _vm._l(
                              _vm.customFieldMap["firstName"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "firstName" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.user.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "color", $$v)
                                        },
                                        expression: "user.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isLastNameVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.lastName"),
                                      "label-for": "lastName",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "lastName",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "user.field.lastName"
                                            ),
                                            "data-vv-name": "user.lastName",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isLastNameReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.lastName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "lastName",
                                                $$v
                                              )
                                            },
                                            expression: "user.lastName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showLastNameError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("user.lastName")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["lastName"] != null
                          ? _vm._l(
                              _vm.customFieldMap["lastName"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "lastName" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNickNameVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.nickName"),
                                      "label-for": "nickName",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "nickName",
                                            type: "text",
                                            readonly: _vm.isNickNameReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.nickName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "nickName",
                                                $$v
                                              )
                                            },
                                            expression: "user.nickName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["nickName"] != null
                          ? _vm._l(
                              _vm.customFieldMap["nickName"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "nickName" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isMobileVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.mobile"),
                                      "label-for": "mobile",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "mobile",
                                            type: "text",
                                            readonly: _vm.isMobileReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.user.mobile,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "mobile", $$v)
                                            },
                                            expression: "user.mobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["mobile"] != null
                          ? _vm._l(
                              _vm.customFieldMap["mobile"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "mobile" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isAccessPolicyVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.field.accessPolicy"),
                                      "label-for": "role",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-input",
                                          _vm._g(
                                            {
                                              staticClass: "form-label",
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.$t(
                                                  "user.placeholder.select_policy"
                                                ),
                                                readonly: "",
                                                "data-vv-as": "Access Policy",
                                                "data-vv-name":
                                                  "accessPolicySelector.name",
                                                "data-vv-delay": "500",
                                              },
                                              model: {
                                                value:
                                                  _vm.accessPolicySelector.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.accessPolicySelector,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "accessPolicySelector.name",
                                              },
                                            },
                                            !_vm.isAccessPolicyReadOnly
                                              ? {
                                                  click:
                                                    _vm.accessPolicySelectorOpen,
                                                }
                                              : {}
                                          )
                                        ),
                                        !_vm.isAccessPolicyReadOnly
                                          ? [
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.accessPolicySelectorOpen,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "button.select"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "info",
                                                        disabled:
                                                          _vm
                                                            .accessPolicySelector
                                                            .name === null,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.state.permissionShow = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "button.customize"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showAccessPolicyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "accessPolicySelector.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["accessPolicy"] != null
                          ? _vm._l(
                              _vm.customFieldMap["accessPolicy"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "accessPolicy" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isEnabledVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.isUserEnabled } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "user-checkbox",
                                        attrs: { id: "enabled_checkbox" },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            switch: "",
                                            size: "lg",
                                            name: "enabled",
                                            disabled: _vm.isEnabledReadOnly,
                                          },
                                          model: {
                                            value: _vm.user.enabled,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "enabled", $$v)
                                            },
                                            expression: "user.enabled",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: "enabled_checkbox",
                                          triggers: "hover",
                                          placement: "topright",
                                          boundary: "viewport",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("user.enableTooltip")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isSuperUserVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "shield"],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "user.field.system_admin"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2614534344
                                    ),
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "user-checkbox",
                                        attrs: { id: "system_admin_chkbox" },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          staticClass: "checkbox-width",
                                          attrs: {
                                            switch: "",
                                            size: "lg",
                                            name: "enabled",
                                            disabled: _vm.isSuperUserReadOnly,
                                          },
                                          model: {
                                            value: _vm.user.superUser,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "superUser",
                                                $$v
                                              )
                                            },
                                            expression: "user.superUser",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: "system_admin_chkbox",
                                          triggers: "hover",
                                          placement: "top",
                                          boundary: "viewport",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "user.system_admin_tooltip"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isLdapEnabledVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", sm: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("user.ldapLoginLabel"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "user-checkbox",
                                        attrs: { id: "ldap_chkbox" },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          staticClass: "checkbox-width",
                                          attrs: {
                                            switch: "",
                                            size: "lg",
                                            name: "ldap",
                                            disabled: _vm.isLdapReadOnly,
                                          },
                                          model: {
                                            value: _vm.user.ldapLogin,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "ldapLogin",
                                                $$v
                                              )
                                            },
                                            expression: "user.ldapLogin",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          target: "ldap_chkbox",
                                          triggers: "hover",
                                          placement: "top",
                                          boundary: "viewport",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("user.ldapLoginTooltip")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["ldapLogin"] != null
                          ? _vm._l(
                              _vm.customFieldMap["ldapLogin"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "ldapLogin" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNoteVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("NoteList", {
                                      attrs: {
                                        readOnly: _vm.isNoteReadOnly,
                                        notes: _vm.notes,
                                      },
                                      on: {
                                        add: _vm.addNote,
                                        edit: _vm.editNote,
                                        toRemove: _vm.removeNote,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.user[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "user[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.state.accessPolicySelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.accessPolicySelectorShow,
              entityService: _vm.accessPolicyUtil,
              preselected: _vm.accessPolicySelector.uuId,
              entity: "ACCESS_POLICY",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "accessPolicySelectorShow", $event)
              },
              ok: _vm.accessPolicyOk,
            },
          })
        : _vm._e(),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "USER",
                    customFields: _vm.customFields,
                    links: "ACCESS_POLICY,NOTE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("PermissionModal", {
        attrs: {
          show: _vm.state.permissionShow,
          isOwnUser: _vm.isOwnUser,
          selected: _vm.permissions,
          permissionProperties: _vm.permissionProps,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "permissionShow", $event)
          },
          success: _vm.permissionOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("user.confirmation.title_email_change"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmEmailChangeOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.continue")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmEmailChangeShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmEmailChangeShow", $$v)
            },
            expression: "state.confirmEmailChangeShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t("user.confirmation.email_change_line1", [
                    this.originEmail,
                  ])
                )
              ),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("user.confirmation.email_change_line2"))),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("user.user_edit"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmSaveChangeBeforeProceedOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.save_continue")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmSaveChangeBeforeProceedShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmSaveChangeBeforeProceedShow", $$v)
            },
            expression: "state.confirmSaveChangeBeforeProceedShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("user.confirmation.save_change_before_proceed"))
              ),
            ]),
          ]),
        ]
      ),
      _c("PasswordModal", {
        attrs: {
          register: !_vm.isUserRegistered,
          tokenId: _vm.tokenId,
          show: _vm.passwordShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.passwordShow = $event
          },
          success: _vm.passwordSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("title_warning"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmRevokingCriticalPermissionOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmRevokingCriticalPermissionShow,
            callback: function ($$v) {
              _vm.confirmRevokingCriticalPermissionShow = $$v
            },
            expression: "confirmRevokingCriticalPermissionShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("statement_risk_losing_account_setting_access")) +
                " "
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }